import * as React from "react";
import Editor from "./editor";
import Tabs from "./tabs";
import * as RecursiveActions from "../services/recursive-actions";
import "../styles/app.css";

const STORAGE_KEY = "ublo_builder_formats";

const App = () => {
  const [ready, setReady] = React.useState(false);
  const [formats, setFormats] = React.useState([]);
  const [currentFormat, setCurrentFormat] = React.useState();
  const tree = currentFormat?.markup || [];
  const flattenedTree = RecursiveActions.flattenMarkup(tree);
  const higherMicroFormatId =
    flattenedTree?.length < 1
      ? 0
      : Math.max(...flattenedTree.map((item) => item.id));

  React.useEffect(() => {
    setFormats((currentFormats) => {
      const newFormats = currentFormats.map((format) => {
        return format.id === currentFormat.id ? currentFormat : format;
      });
      return newFormats;
    });
  }, [currentFormat]);

  React.useEffect(() => {
    if (ready) {
      if (!currentFormat) {
        const [firstFormat] = formats;
        setCurrentFormat(firstFormat);
      }
      window.localStorage.setItem(STORAGE_KEY, JSON.stringify(formats));
    }
  }, [currentFormat, formats, ready]);

  React.useEffect(() => {
    if (!ready) {
      const storedFormats = window.localStorage.getItem(STORAGE_KEY);
      if (storedFormats) {
        setFormats(JSON.parse(storedFormats));
      }
      setReady(true);
    }
  }, [ready, setReady]);

  return (
    <div className="app">
      <Tabs
        formats={formats}
        setFormats={setFormats}
        currentFormat={currentFormat}
        setCurrentFormat={setCurrentFormat}
      />
      <Editor
        currentFormat={currentFormat}
        setCurrentFormat={setCurrentFormat}
        setFormats={setFormats}
        tree={tree}
        higherMicroFormatId={higherMicroFormatId}
      />
    </div>
  );
};

export default App;
