import * as React from "react";
import Popup from "../components/popup";

const AppContext = React.createContext();

export const useAppContext = () => {
  const context = React.useContext(AppContext);
  if (!context)
    throw new Error(`useAppContext must be used within a AppContextProvider`);
  return context;
};

export const defaultPopupContent = {
  title: undefined, // string
  content: undefined, // can be a module Component or a string
  callback: undefined, // can be a function or undefined/null
  popupClassName: undefined, // cab be a string or undefined/null
  buttons: [],
};

const AppContextProvider = (props) => {
  const [theme, setTheme] = React.useState("light");
  const [popupContent, setPopupContent] = React.useState(defaultPopupContent);

  const { title, content, callback, popupClassName, buttons } = popupContent;
  const type = typeof content === "string" ? "string" : "module";
  const Module = type === "module" && content;

  const closePopup = () => {
    callback?.();
    setPopupContent(defaultPopupContent);
  };

  return (
    <AppContext.Provider
      value={{
        popupContent,
        setPopupContent,
        theme,
        setTheme,
      }}
      {...props}
    >
      {props.children}
      {content && (
        <Popup
          title={title}
          className={popupClassName}
          close={closePopup}
          buttons={buttons}
        >
          {Module ? <Module /> : content}
        </Popup>
      )}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
