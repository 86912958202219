import * as React from "react";
import Button from "./button";
import Input from "./input";
import * as Icons from "./icons";
import Label from "./label";
import "../styles/table-rows-editor.css";

const TableRowsEditor = ({ item, updateItem }) => {
  const { id, markup = [] } = item;

  const update = React.useCallback(
    (items) => {
      if (items.length) {
        updateItem(id, "markup", items);
      } else {
        updateItem(id, "markup", undefined);
      }
    },
    [id, updateItem]
  );

  const addRow = () => {
    const newIndex = markup.length + 1;
    const name = `Ligne n°${newIndex}`;
    const rowMarkup = `<tr><td>Contenu n°${newIndex}</td></tr>`;
    const newRow = { name, markup: rowMarkup };
    update([...markup, newRow]);
  };

  const updateRow = (field, index) => (value) => {
    const newRows = markup.map((m, i) => {
      if (i === index) {
        return { ...m, [field]: value };
      } else {
        return m;
      }
    });
    update(newRows);
  };

  const removeRow = (index) => (e) => {
    if (markup.length === 1) return;
    const newRows = markup.filter((_, i) => i !== index);
    update(newRows);
  };

  const updateMarkupInputHeight = (e) => {
    const container = e.target.closest(".input__inner");
    container.style.height = "inherit";
    const computedStyle = window.getComputedStyle(e.target);
    const height =
      parseInt(computedStyle.getPropertyValue("padding-top"), 10) +
      e.target.scrollHeight +
      parseInt(computedStyle.getPropertyValue("padding-bottom"), 10);
    container.style.height = `${height}px`;
  };

  return (
    <div className="table-rows-editor">
      <Label noLabelTag inline>
        Ajouter{" "}
        <Button
          kind="secondary"
          Icon={Icons.Plus}
          compact
          onClick={addRow}
        ></Button>
      </Label>
      <div className="table-rows-editor__inner">
        {markup.map((row, i) => {
          const { name, markup: rowMarkup } = row;
          return (
            <div key={i} className="table-rows-editor__item">
              <Input
                type="text"
                label="Nom"
                value={name}
                onChange={updateRow("name", i)}
                inline
              />
              <Input
                type="textarea"
                label="Markup"
                value={rowMarkup}
                onChange={updateRow("markup", i)}
                onFocus={updateMarkupInputHeight}
                onInput={updateMarkupInputHeight}
              />
              <Button
                kind="danger"
                className="table-rows-editor__item-remove"
                Icon={Icons.Bin}
                compact
                onClick={removeRow(i)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TableRowsEditor;
