import * as React from "react";
import Input from "./input";
import "../styles/advanced-options.css";
import Select from "./select";
import Label from "./label";
import MicroFormatOptions from "./micro-format-options";
import TableRowsEditor from "./table-rows-editor";

const AdvancedOptions = ({ presets, item, microFormat, updateItem }) => {
  const { id, format, tag, selector, markup, children } = item;
  const { Icon, rules } = microFormat;
  const { allowedTags, advancedOptions } = rules;
  const { focusOn } = presets;

  const isContainer = format === "__CONTAINER__";
  const markupIsArray = Array.isArray(markup);

  const title = isContainer ? "Conteneur" : format;

  const updateTag = (value) => {
    updateItem(id, "tag", value);
  };

  const updateSelector = (value) => {
    updateItem(id, "selector", value);
  };

  const updateMarkup = (value) => {
    updateItem(id, "markup", value);
  };

  const updateMarkupInputHeight = (e) => {
    const container = e.target.closest(".input__inner");
    container.style.height = "inherit";
    const computedStyle = window.getComputedStyle(e.target);
    const height =
      parseInt(computedStyle.getPropertyValue("padding-top"), 10) +
      e.target.scrollHeight +
      parseInt(computedStyle.getPropertyValue("padding-bottom"), 10);
    container.style.height = `${height}px`;
  };

  return (
    <div className="advanced-options">
      <div className="advanced-options__header">
        <Icon className="advanced-options__icon" />
        <div className="advanced-options__format">{title}</div>
      </div>
      <div className="advanced-options__form">
        <div className="advanced-options__form-group">
          <Select
            id="tag"
            label="Tag HTML"
            value={tag}
            onChange={updateTag}
            options={allowedTags}
            disabled={allowedTags.length < 2}
            autoFocus={focusOn === "tag"}
            compact
          />
          <Input
            id="selector"
            label="Sélecteur"
            value={selector}
            onChange={updateSelector}
            autoFocus={focusOn === "selector"}
            compact
          />
        </div>
        {!children?.length > 0 && !markupIsArray && (
          <Input
            type="textarea"
            id="markup"
            label="Markup"
            value={markup}
            onChange={updateMarkup}
            onInput={updateMarkupInputHeight}
            onFocus={updateMarkupInputHeight}
            autoFocus={focusOn === "markup"}
            compact
          />
        )}
        {markupIsArray && (
          <div className="advanced-options__form-group advanced-options__form-group--column">
            <Label important>Lignes de tableau</Label>
            <TableRowsEditor item={item} updateItem={updateItem} />
          </div>
        )}
        {advancedOptions && (
          <>
            <Label important>Options</Label>
            <MicroFormatOptions
              format={format}
              item={item}
              updateItem={updateItem}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default AdvancedOptions;
