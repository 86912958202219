import * as React from "react";
import * as Icons from "./icons";
import { useAppContext } from "../hooks/use-app-context";
import "../styles/theme-switcher.css";

const STORAGE_KEY = "user_theme";

const ThemeSwitcher = () => {
  const { theme, setTheme } = useAppContext();

  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    document.documentElement.dataset.theme = newTheme;
    window.localStorage.setItem(STORAGE_KEY, newTheme);
  };

  React.useEffect(() => {
    const storedTheme = window.localStorage.getItem(STORAGE_KEY);
    console.log(storedTheme);
    if (storedTheme) {
      setTheme(storedTheme);
      document.documentElement.dataset.theme = storedTheme;
    }
  }, [setTheme]);

  const Icon = theme === "light" ? Icons.Sun : Icons.Moon;

  return (
    <button className="theme-switcher" onClick={toggleTheme}>
      <Icon className="theme-switcher__icon" />
    </button>
  );
};

export default ThemeSwitcher;
