import * as React from "react";
import classnames from "classnames";
import "../styles/input.css";
import Label from "./label";

const Input = ({
  id,
  type = "text",
  label,
  error,
  value,
  onChange,
  onInput,
  onKeyDown,
  onKeyUp,
  onFocus,
  inputRef,
  placeholder,
  decoration,
  decorationPosition = "left",
  disabled,
  autoFocus,
  compact,
  inline,
}) => {
  const updateValue = (e) => {
    onChange(e.target.value);
  };

  const classes = classnames("input", {
    "input--compact": compact,
    "input--inline": inline,
    "input--error": error,
    "input--right-decoration": decorationPosition === "right",
  });

  const Tag = type === "textarea" ? "textarea" : "input";

  return (
    <div className={classes} data-id={id}>
      {label && (
        <Label htmlFor={id} compact={compact} inline={inline}>
          {label}
        </Label>
      )}
      <div className="input__outer">
        {decoration && <div className="input__decoration">{decoration}</div>}
        <div className="input__inner">
          <Tag
            ref={inputRef}
            id={id}
            name={id}
            className="input__field"
            type={type === "textarea" ? undefined : type}
            onChange={updateValue}
            onInput={onInput}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            onFocus={onFocus}
            value={value}
            placeholder={placeholder}
            autoFocus={autoFocus}
            disabled={disabled}
          />
        </div>
      </div>
      {error && <div className="input__error">{error}</div>}
    </div>
  );
};

export default Input;
