import * as React from "react";
import classnames from "classnames";
import Label from "./label";

const Select = ({
  id,
  label,
  error,
  options = [],
  value,
  onChange,
  onKeyDown,
  inputRef,
  placeholder,
  decoration,
  decorationPosition = "left",
  disabled,
  autoFocus,
  allowEmpty,
  compact,
  inline,
}) => {
  const updateValue = (e) => {
    onChange(e.target.value);
  };

  const classes = classnames("input input--select", {
    "input--compact": compact,
    "input--inline": inline,
    "input--error": error,
    "input--right-decoration": decorationPosition === "right",
  });
  return (
    <div className={classes} data-id={id}>
      {label && (
        <Label htmlFor={id} compact={compact} inline={inline}>
          {label}
        </Label>
      )}
      <div className="input__outer">
        {decoration && <div className="input__decoration">{decoration}</div>}
        <div className="input__inner">
          <select
            ref={inputRef}
            id={id}
            name={id}
            className="input__field"
            onChange={updateValue}
            onKeyDown={onKeyDown}
            value={value}
            placeholder={placeholder}
            autoFocus={autoFocus}
            disabled={disabled}
          >
            {allowEmpty && <option value=""></option>}
            {options.map((option) => (
              <option
                key={option?.value || option}
                value={option?.value || option}
              >
                {option?.text || option}
              </option>
            ))}
          </select>
        </div>
      </div>
      {error && <div className="input__error">{error}</div>}
    </div>
  );
};

export default Select;
