import * as React from "react";
import classnames from "classnames";
import "../styles/label.css";

const Label = ({
  htmlFor,
  important,
  compact,
  inline,
  noLabelTag,
  children,
}) => {
  const classes = classnames("label", {
    "label--compact": compact,
    "label--inline": inline,
    "label--important": important,
  });

  const Tag = noLabelTag ? "div" : "label";

  return (
    <Tag htmlFor={noLabelTag ? undefined : htmlFor} className={classes}>
      {children}
    </Tag>
  );
};

export default Label;
