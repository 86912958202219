import * as React from "react";
import classnames from "classnames";
import * as Icons from "./icons";
import "../styles/tabs.css";
import ThemeSwitcher from "./theme-switcher";

const SCROLL_DISTANCE = 200;

const Tabs = ({ formats, setFormats, currentFormat, setCurrentFormat }) => {
  const outerRef = React.useRef();
  const [overflowVisible, setOverflowVisible] = React.useState(false);
  const [maxScroll, setMaxScroll] = React.useState(0);
  const [currentScroll, setCurrentScroll] = React.useState(0);

  const addFormat = () => {
    setFormats((currentFormats) => {
      const ids = currentFormats.map((f) => f.id);
      const newId = ids.length ? Math.max(...ids) + 1 : 1;
      const newFormat = {
        id: newId,
        name: "Nouveau format",
        cssClass: "new-format",
      };
      setCurrentFormat(newFormat);
      return [...currentFormats, newFormat];
    });
  };

  const removeFormat = (id) => {
    setFormats((currentFormats) => {
      const newFormats = currentFormats.filter((format) => format.id !== id);
      if (!newFormats.length) {
        setCurrentFormat(undefined);
      } else if (currentFormat.id === id) {
        setCurrentFormat(formats[0]);
      }
      return newFormats;
    });
  };

  const checkScrollValue = () => {
    const outer = outerRef.current;
    if (outer) {
      setCurrentScroll(Math.round(outer.scrollLeft));
    }
  };

  const observerCallback = React.useCallback((entries) => {
    const [entry] = entries;
    const isMutation = entry.type === "childList";

    const outer = isMutation ? outerRef.current : entry.target;
    const inner = isMutation ? entry.target : entry.target.firstElementChild;

    if (!inner) return;

    const outerWidth = Math.round(
      isMutation ? outer.clientWidth || 0 : entry.borderBoxSize[0].inlineSize
    );
    const innerWidth = Math.round(inner.clientWidth);
    const newMaxScroll = Math.abs(innerWidth - outerWidth);

    setOverflowVisible(innerWidth > outerWidth);
    setMaxScroll(newMaxScroll <= 1 ? 0 : newMaxScroll);
  }, []);

  const scrollToRight = () => {
    const outer = outerRef.current;
    if (!outer) return;
    const newScroll = Math.round(currentScroll + SCROLL_DISTANCE);
    setCurrentScroll(newScroll > maxScroll ? maxScroll : newScroll);
    outer.scrollLeft = newScroll;
  };

  const scrollToLeft = () => {
    const outer = outerRef.current;
    if (!outer) return;
    const newScroll = Math.round(currentScroll - SCROLL_DISTANCE);
    setCurrentScroll(newScroll < 0 ? 0 : newScroll);
    outer.scrollLeft = newScroll;
  };

  React.useEffect(() => {
    const outer = outerRef.current;
    if (outer) {
      const resizeObserver = new ResizeObserver(observerCallback);
      const mutationObserver = new MutationObserver(observerCallback);
      resizeObserver.observe(outer);
      mutationObserver.observe(outer, { childList: true, subtree: true });
      return () => {
        resizeObserver.disconnect();
        mutationObserver.disconnect();
      };
    }
  }, [observerCallback]);

  return (
    <div className="tabs">
      <div ref={outerRef} className="tabs__outer" onScroll={checkScrollValue}>
        <div className="tabs__inner">
          {formats.map((format) => {
            const { id, name } = format;

            const isCurrent = currentFormat?.id === id;

            const updateCurrentFormat = () => setCurrentFormat(format);

            const classes = classnames("tabs__tab", {
              "tabs__tab--current": isCurrent,
            });

            return (
              <div key={id} data-id={id} className={classes}>
                <button
                  className="tabs__tab-name"
                  onClick={updateCurrentFormat}
                >
                  {name}
                </button>
                <button
                  className="tabs__tab-close"
                  onClick={() => removeFormat(id)}
                >
                  <Icons.Cross className="tabs__tab-close-icon" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
      <div className="tabs__controls">
        <button className="tabs__add-button" onClick={addFormat}>
          <Icons.Plus className="tabs__add-icon" />
        </button>
        <button
          className="tabs__control tabs__control--left"
          disabled={!overflowVisible || currentScroll === 0}
          onClick={scrollToLeft}
        >
          <Icons.ChevronLeft className="tabs__control-icon" />
        </button>
        <button
          className="tabs__control tabs__control--right"
          disabled={!overflowVisible || currentScroll === maxScroll}
          onClick={scrollToRight}
        >
          <Icons.ChevronRight className="tabs__control-icon" />
        </button>
        <ThemeSwitcher />
      </div>
    </div>
  );
};

export default Tabs;
