import * as React from "react";
import { motion } from "framer-motion";
import { ReactJason } from "react-jason";
import "../styles/json-preview.css";

const theme = {
  styles: {
    root: { backgroundColor: "var(--cms-grey-000)" },
    attribute: { color: "var(--cms-secondary-color)", fontWeight: "700" },
    unquotedAttribute: { color: "var(--cms-grey-500)", fontWeight: "700" },
    string: { color: "var(--cms-grey-500)" },
    nil: { color: "var(--cms-yellow)", fontWeight: "700" },
    number: { color: "var(--cms-yellow)", fontWeight: "700" },
    boolean: { color: "var(--cms-yellow)", fontWeight: "700" },
    punctuation: { color: "var(--cms-grey-500)" },
  },
};

const JsonPreview = ({ currentFormat = {} }) => {
  const { id, ...formatWithoutId } = currentFormat;
  return (
    <motion.div
      className="json-preview"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="json-preview__inner">
        <ReactJason value={formatWithoutId} theme={theme} />
      </div>
    </motion.div>
  );
};

export default JsonPreview;
