import * as React from "react";
import { AnimatePresence, LayoutGroup } from "framer-motion";
import classnames from "classnames";
import { DndContext } from "@dnd-kit/core";
import "../styles/editor.css";
import Details from "./details";
import Label from "./label";
import MicroFormatList from "./micro-format-list";
import FormatTree from "./format-tree";
import JsonPreview from "./json-preview";
import * as Icons from "./icons";
import * as RecursiveActions from "../services/recursive-actions";
import Button from "./button";
import { useAppContext } from "../hooks/use-app-context";

const STORAGE_KEY = "ublo_builder_preview_opened";

const Editor = ({
  currentFormat,
  setCurrentFormat,
  tree,
  higherMicroFormatId,
  setFormats,
}) => {
  const [ready, setReady] = React.useState(false);
  const [dragging, setDragging] = React.useState(false);
  const [previewOpened, setPreviewOpened] = React.useState(true);
  const [detailsOpened, setDetailsOpened] = React.useState(true);

  const { setPopupContent } = useAppContext();

  const preview = RecursiveActions.buildPreview(currentFormat);

  const addFormat = () => {
    setFormats((currentFormats) => {
      const ids = currentFormats.map((f) => f.id);
      const newId = ids.length ? Math.max(...ids) + 1 : 1;
      const newFormat = {
        id: newId,
        name: "Nouveau format",
        cssClass: "new-format",
      };
      setCurrentFormat(newFormat);
      return [...currentFormats, newFormat];
    });
  };

  const removeItem = (id) => {
    const newTree = RecursiveActions.removeItem(currentFormat.markup, id);
    setCurrentFormat((current) => {
      return { ...current, markup: newTree };
    });
  };

  const updateItem = (id, key, value) => {
    const newTree = RecursiveActions.updateItem(
      currentFormat.markup,
      id,
      key,
      value
    );
    setCurrentFormat((current) => {
      return { ...current, markup: newTree };
    });
  };

  const moveItem = (item, targetId, position) => {
    if (item.id === targetId) return;
    const treeWithoutItem = RecursiveActions.removeItem(
      currentFormat.markup,
      item.id
    );
    const newTree = RecursiveActions.moveItem(
      treeWithoutItem,
      item,
      targetId,
      position
    );
    setCurrentFormat((current) => {
      return { ...current, markup: newTree };
    });
  };

  const dropItem = (e) => {
    const { active, over } = e;
    if (
      over &&
      over.data.current.accepts.includes(active.data.current.type) &&
      active.id !== over.id
    ) {
      if (over.id === "inner") {
        setCurrentFormat((current) => {
          const { markup = [] } = current;
          return { ...current, markup: [...markup, active.data.current.item] };
        });
        setDragging(false);
        return;
      }
      const { item, position } = over.data.current;
      moveItem(active.data.current.item, item.id, position);
      setDragging(false);
    }
    setDragging(false);
  };

  const updateDragging = () => {
    setDragging(true);
  };

  const togglePreview = () => {
    setPreviewOpened((current) => !current);
  };

  const toggleDetails = () => {
    setDetailsOpened((current) => !current);
  };

  const exportJson = async () => {
    const json = JSON.stringify(preview, undefined, 2);
    await navigator.clipboard.writeText(json);
    setPopupContent({
      title: "C'est tout bon !",
      content: "Format copié dans votre presse-papier 😊",
      buttons: [{ label: "OK", onClick: "close" }],
    });
  };

  React.useEffect(() => {
    if (!ready) return;
    if (previewOpened) {
      window.localStorage.setItem(STORAGE_KEY, "true");
    } else {
      window.localStorage.removeItem(STORAGE_KEY);
    }
  }, [previewOpened, ready]);

  React.useEffect(() => {
    if (ready) return;
    const storedValue = window.localStorage.getItem(STORAGE_KEY);
    if (storedValue) {
      setPreviewOpened(true);
    }
    setReady(true);
  }, [ready]);

  const classes = classnames("editor", {
    "editor--dragging": dragging,
    "editor--preview": previewOpened,
  });

  const PreviewIcon = previewOpened ? Icons.Eye : Icons.EyeOff;
  const DetailsIcon = detailsOpened ? Icons.Eye : Icons.EyeOff;

  return (
    <DndContext
      key={currentFormat?.id}
      onDragStart={updateDragging}
      onDragEnd={dropItem}
    >
      <div className={classes}>
        <div className="editor__side-bar">
          <div className="editor__side-bar-top">
            <Label important noLabelTag>
              Détails{" "}
              {currentFormat && (
                <Button
                  kind="secondary"
                  Icon={DetailsIcon}
                  onClick={toggleDetails}
                  compact
                />
              )}
            </Label>
            <AnimatePresence exitBeforeEnter>
              {currentFormat && detailsOpened && (
                <Details
                  currentFormat={currentFormat}
                  setCurrentFormat={setCurrentFormat}
                  disabled={!currentFormat}
                />
              )}
            </AnimatePresence>
          </div>
          <div className="editor__side-bar-bottom">
            <Label important>Micro-formats</Label>
            <MicroFormatList
              currentFormat={currentFormat}
              higherMicroFormatId={higherMicroFormatId}
            />
          </div>
        </div>
        <div className="editor__content">
          <div className="editor__content-buttons">
            <Button
              className="editor__content-button editor__content-button--export"
              Icon={Icons.Save}
              iconPosition="end"
              onClick={exportJson}
              disabled={!currentFormat}
            >
              Exporter
            </Button>
            {/* <Button
              kind="secondary"
              className="editor__content-button editor__content-button--import"
              Icon={Icons.Import}
            >
              Importer
            </Button> */}
            <Button
              kind={previewOpened ? "primary" : "secondary"}
              className="editor__content-button editor__content-button--preview"
              onClick={togglePreview}
              Icon={PreviewIcon}
              disabled={!currentFormat}
            >
              JSON
            </Button>
          </div>
          <div className="editor__content-inner">
            <LayoutGroup id="content">
              <FormatTree
                tree={tree}
                removeItem={removeItem}
                updateItem={updateItem}
                addFormat={addFormat}
                empty={!currentFormat}
              />
              <AnimatePresence>
                {previewOpened && currentFormat && (
                  <JsonPreview currentFormat={preview} />
                )}
              </AnimatePresence>
            </LayoutGroup>
          </div>
        </div>
      </div>
    </DndContext>
  );
};

export default React.memo(Editor);
