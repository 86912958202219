export const buildTree = (tree) => {
  return Object.entries(tree).map(([name, value]) => {
    const hasMarkup = value.markup && typeof value.markup !== "string";
    return [name, hasMarkup ? buildTree(value) : value];
  });
};

export const flattenMarkup = (markup) => {
  return markup.reduce((acc, microFormat) => {
    if (microFormat.children?.length > 0) {
      return [...acc, ...flattenMarkup(microFormat.children)];
    }
    return [...acc, microFormat];
  }, []);
};

const convertMicroFormats = (microFormats) => {
  return microFormats.reduce((acc, item) => {
    const { format, tag, selector = "", children, markup = "", options } = item;
    const isContainer = format === "__CONTAINER__";
    const key = `${tag}${selector}`;
    const childrenMarkup =
      children?.length > 0 ? convertMicroFormats(children) : markup;
    const itemMarkup = isContainer
      ? childrenMarkup || {}
      : { format, markup: childrenMarkup, options };
    return { ...acc, [key]: itemMarkup };
  }, {});
};

const formatOrderKeys = [
  "name",
  "group",
  "description",
  "preview",
  "cssClass",
  "unique",
  "variants",
];

export const buildPreview = (format) => {
  if (!format) return {};
  const { id, ...formatWithoutId } = format;
  const orderedFormat = JSON.parse(
    JSON.stringify(formatWithoutId, formatOrderKeys)
  );
  if (!formatWithoutId.markup) {
    return orderedFormat;
  }
  return {
    ...orderedFormat,
    markup: convertMicroFormats(formatWithoutId.markup),
  };
};

export const removeItem = (microFormats, id) => {
  return microFormats.reduce((acc, microFormat) => {
    if (microFormat.id === id) {
      return acc;
    }
    if (microFormat.children?.length > 0) {
      return [
        ...acc,
        { ...microFormat, children: removeItem(microFormat.children, id) },
      ];
    }
    return [...acc, microFormat];
  }, []);
};

export const updateItem = (microFormats, id, key, value) => {
  return microFormats.reduce((acc, microFormat) => {
    if (microFormat.id === id) {
      return [...acc, { ...microFormat, [key]: value }];
    }
    if (microFormat.children?.length > 0) {
      return [
        ...acc,
        {
          ...microFormat,
          children: updateItem(microFormat.children, id, key, value),
        },
      ];
    }
    return [...acc, microFormat];
  }, []);
};

export const moveItem = (microFormats, item, targetId, position) => {
  return microFormats.reduce((acc, microFormat) => {
    const { children = [] } = microFormat;
    if (microFormat.id === targetId) {
      if (position === "inside") {
        return [...acc, { ...microFormat, children: [...children, item] }];
      }
      if (position === "before") {
        return [...acc, item, microFormat];
      }
      if (position === "after") {
        return [...acc, microFormat, item];
      }
    }
    if (microFormat.children?.length > 0) {
      return [
        ...acc,
        {
          ...microFormat,
          children: moveItem(microFormat.children, item, targetId, position),
        },
      ];
    }
    return [...acc, microFormat];
  }, []);
};
