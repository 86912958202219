import * as React from "react";
import "../styles/micro-format-list.css";
import { useDraggable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import * as MicroFormats from "../data/micro-formats";

const MICRO_FORMATS = MicroFormats.list;

const TABLE_FORMATS = ["TableFormat", "TarifsTableFormat"];

const Item = ({ name, item, currentFormat, higherMicroFormatId }) => {
  const { cssClass } = currentFormat;
  const { Icon, title, rules } = item;
  const { defaultTag, defaultMarkup } = rules;

  const nextId = higherMicroFormatId + 1;
  const isArray = !TABLE_FORMATS.includes(name) && Array.isArray(defaultMarkup);

  const prop = isArray ? "children" : "markup";

  const buildedMicroFormat = {
    id: nextId,
    format: name,
    tag: defaultTag,
    selector: `.${cssClass}__${nextId}`,
    [prop]: defaultMarkup,
  };

  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: name,
    data: { item: buildedMicroFormat, type: "inner" },
  });

  const style = { transform: CSS.Translate.toString(transform) };

  return (
    <div
      ref={setNodeRef}
      className="micro-format-list__item"
      style={style}
      tabIndex={-1}
      {...listeners}
      {...attributes}
    >
      <Icon className="micro-format-list__item-icon" />
      <div className="micro-format-list__item-title">{title}</div>
    </div>
  );
};

const MicroFormatList = ({ currentFormat, higherMicroFormatId }) => {
  return (
    <div className="micro-format-list">
      {currentFormat &&
        Object.keys(MICRO_FORMATS).map((key) => {
          return (
            <Item
              key={key}
              name={key}
              item={MICRO_FORMATS[key]}
              currentFormat={currentFormat}
              higherMicroFormatId={higherMicroFormatId}
            />
          );
        })}
    </div>
  );
};

export default MicroFormatList;
