import * as React from "react";

const Icon = ({ width = 24, height = 24, ...props }) => (
  <svg
    viewBox={`0 0 ${width} ${height}`}
    width={width}
    height={height}
    {...props}
  >
    {props.children}
  </svg>
);

export const Valraiso = (props) => (
  <Icon {...props}>
    <path d="M0 22.12 12.07 1.87l1.72 2.87L3.46 22.12H0Zm10.2 0 1.71-2.86 6.95-.02-3.45-5.87 1.72-2.86 5.15 8.72 1.72 2.9H10.2Z" />
  </Icon>
);

export const Plus = (props) => (
  <Icon {...props}>
    <path d="M13.1 1c-.06-.57-.55-1-1.14-1-.63 0-1.16.52-1.16 1.16v9.64H1.16l-.16.02c-.57.06-1 .55-1 1.14a1.16 1.16 0 0 0 1.16 1.16h9.64v9.8a1.16 1.16 0 0 0 2.32-.16v-9.64h9.8a1.16 1.16 0 0 0-.16-2.32h-9.64V1.16L13.1 1Z" />
  </Icon>
);

export const ChevronRight = (props) => (
  <Icon {...props}>
    <path d="M5.28.29A1 1 0 0 1 6.67.27L19 12.11 6.66 23.73a1 1 0 0 1-1.39-.02.95.95 0 0 1 .03-1.36L16.18 12.1 5.3 1.65a.95.95 0 0 1 0-1.36Z" />
  </Icon>
);

export const ChevronLeft = (props) => (
  <Icon {...props}>
    <path d="M18.73.28a.9.9 0 0 1-.02 1.3L7.71 12l11 10.42a.9.9 0 0 1 .02 1.31.96.96 0 0 1-1.33.02L5 11.99 17.4.26a.96.96 0 0 1 1.33.02Z" />
  </Icon>
);

export const Cross = (props) => (
  <Icon {...props}>
    <path d="M2.02 24a2.02 2.02 0 0 1-1.43-3.44L20.56.59a2.02 2.02 0 1 1 2.85 2.85L3.45 23.41c-.4.4-.92.59-1.43.59Z" />
    <path d="M21.98 24a2 2 0 0 1-1.43-.6L.6 3.46A2.02 2.02 0 1 1 3.44.59l19.97 19.97A2.02 2.02 0 0 1 21.98 24Z" />
  </Icon>
);

export const Image = (props) => (
  <Icon {...props}>
    <path d="M5 4a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H5ZM2 5a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v14a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5Z" />
    <path d="M8.5 8a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1ZM6 8.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0ZM15.3 9.3a1 1 0 0 1 1.4 0l5 5a1 1 0 0 1-1.4 1.4L16 11.42 5.7 21.71a1 1 0 0 1-1.4-1.42l11-11Z" />
  </Icon>
);

export const Cart = (props) => (
  <Icon {...props}>
    <path d="M5.2 1.4A1 1 0 0 1 6 1h12a1 1 0 0 1 .8.4l3 4a1 1 0 0 1 .2.6v14a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V6a1 1 0 0 1 .2-.6l3-4ZM6.5 3 4 6.33V20a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V6.33L17.5 3h-11Z" />
    <path d="M2 6a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1ZM8 9a1 1 0 0 1 1 1 3 3 0 0 0 6 0 1 1 0 1 1 2 0 5 5 0 1 1-10 0 1 1 0 0 1 1-1Z" />
  </Icon>
);

export const Text = (props) => (
  <Icon {...props}>
    <path d="M3 4a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v3a1 1 0 1 1-2 0V5H5v2a1 1 0 0 1-2 0V4ZM8 20a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1Z" />
    <path d="M12 3a1 1 0 0 1 1 1v16a1 1 0 1 1-2 0V4a1 1 0 0 1 1-1Z" />
  </Icon>
);

export const Link = (props) => (
  <Icon {...props}>
    <path d="M12.78 2.75a6 6 0 0 1 8.48 8.48l-.01.02-3 3a6 6 0 0 1-9.05-.65 1 1 0 1 1 1.6-1.2 4 4 0 0 0 6.03.43l3-3a4 4 0 0 0-5.66-5.65l-1.71 1.7a1 1 0 0 1-1.42-1.4l1.73-1.72Z" />
    <path d="M7.9 8.37a6 6 0 0 1 6.9 2.03 1 1 0 0 1-1.6 1.2 4 4 0 0 0-6.03-.43l-3 3a4 4 0 0 0 5.66 5.65l1.7-1.7a1 1 0 0 1 1.42 1.4l-1.71 1.72-.02.01a6 6 0 0 1-8.48-8.48l.01-.02 3-3A6 6 0 0 1 7.9 8.37Z" />
  </Icon>
);

export const Film = (props) => (
  <Icon {...props}>
    <path d="M4.18 3C3.53 3 3 3.53 3 4.18v15.64c0 .65.53 1.18 1.18 1.18h15.64c.65 0 1.18-.53 1.18-1.18V4.18C21 3.53 20.47 3 19.82 3H4.18ZM1 4.18A3.18 3.18 0 0 1 4.18 1h15.64A3.18 3.18 0 0 1 23 4.18v15.64A3.18 3.18 0 0 1 19.82 23H4.18A3.18 3.18 0 0 1 1 19.82V4.18Z" />
    <path d="M7 1a1 1 0 0 1 1 1v20a1 1 0 1 1-2 0V2a1 1 0 0 1 1-1ZM17 1a1 1 0 0 1 1 1v20a1 1 0 1 1-2 0V2a1 1 0 0 1 1-1Z" />
    <path d="M1 12a1 1 0 0 1 1-1h20a1 1 0 1 1 0 2H2a1 1 0 0 1-1-1ZM1 7a1 1 0 0 1 1-1h5a1 1 0 0 1 0 2H2a1 1 0 0 1-1-1ZM1 17a1 1 0 0 1 1-1h5a1 1 0 1 1 0 2H2a1 1 0 0 1-1-1ZM16 17a1 1 0 0 1 1-1h5a1 1 0 1 1 0 2h-5a1 1 0 0 1-1-1ZM16 7a1 1 0 0 1 1-1h5a1 1 0 1 1 0 2h-5a1 1 0 0 1-1-1Z" />
  </Icon>
);

export const Map = (props) => (
  <Icon {...props}>
    <path d="M7.5 1.13a1 1 0 0 1 .95-.02l7.52 3.76 6.53-3.74A1 1 0 0 1 24 2v16a1 1 0 0 1-.5.87l-7 4a1 1 0 0 1-.95.02l-7.52-3.76-6.53 3.74A1 1 0 0 1 0 22V6a1 1 0 0 1 .5-.87l7-4Zm.53 2L2 6.58v13.7l5.5-3.15a1 1 0 0 1 .95-.02l7.52 3.76L22 17.42V3.72l-5.5 3.15a1 1 0 0 1-.95.02L8.03 3.13Z" />
    <path d="M8 1a1 1 0 0 1 1 1v16a1 1 0 1 1-2 0V2a1 1 0 0 1 1-1ZM16 5a1 1 0 0 1 1 1v16a1 1 0 1 1-2 0V6a1 1 0 0 1 1-1Z" />
  </Icon>
);

export const Globe = (props) => (
  <Icon {...props}>
    <path d="M12 3a9 9 0 1 0 0 18 9 9 0 0 0 0-18ZM1 12a11 11 0 1 1 22 0 11 11 0 0 1-22 0Z" />
    <path d="M1 12a1 1 0 0 1 1-1h20a1 1 0 1 1 0 2H2a1 1 0 0 1-1-1Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 12a14.3 14.3 0 0 0 3 8.45A14.3 14.3 0 0 0 15 12a14.3 14.3 0 0 0-3-8.45A14.3 14.3 0 0 0 9 12Zm3-10-.74-.67A16.3 16.3 0 0 0 7 12.03a16.3 16.3 0 0 0 4.26 10.64 1 1 0 0 0 1.48 0A16.3 16.3 0 0 0 17 11.97a16.3 16.3 0 0 0-4.26-10.64L12 2Z"
    />
  </Icon>
);

export const Play = (props) => (
  <Icon {...props}>
    <path d="M12 3a9 9 0 1 0 0 18 9 9 0 0 0 0-18ZM1 12a11 11 0 1 1 22 0 11 11 0 0 1-22 0Z" />
    <path d="M9.53 7.12a1 1 0 0 1 1.02.05l6 4a1 1 0 0 1 0 1.66l-6 4A1 1 0 0 1 9 16V8c0-.37.2-.7.53-.88ZM11 9.87v4.26L14.2 12 11 9.87Z" />
  </Icon>
);

export const Table = (props) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.19 0H2.8A2.82 2.82 0 0 0 0 2.81V21.2A2.82 2.82 0 0 0 2.81 24H21.2A2.82 2.82 0 0 0 24 21.19V2.8A2.82 2.82 0 0 0 21.19 0ZM2.8 1.88H21.2c.51 0 .93.42.93.93V7H1.88V2.81c0-.51.43-.94.94-.94ZM5.5 9H1.87v3H5.5V9Zm0 5H1.87v3H5.5v-3Zm2 3v-3h14.63v3H7.5Zm-2 2H1.87v2.19c0 .51.43.93.94.93H5.5V19Zm2 3.13V19h14.63v2.19c0 .51-.43.93-.94.93H7.5ZM7.5 12V9h14.63v3H7.5Z"
    />
  </Icon>
);

export const Brackets = (props) => (
  <Icon {...props}>
    <path d="M8.79 23.08a4.48 4.48 0 0 1-3.1-1c-.76-.67-1.1-1.6-1.02-2.8l.26-4.07c.05-.83-.07-1.42-.37-1.78-.3-.36-.96-.54-1.98-.54H0v-1.67h2.58c1 0 1.66-.18 1.95-.54.32-.36.45-.96.4-1.81l-.26-4.06C4.6 3.6 4.93 2.67 5.7 2A4.48 4.48 0 0 1 8.79 1H9.9v1.67H8.8c-.74 0-1.31.18-1.72.54-.4.36-.59.9-.54 1.6l.26 4.06a3.1 3.1 0 0 1-.52 2.11c-.39.57-.95.92-1.67 1.06.72.14 1.28.5 1.67 1.06.41.56.58 1.27.52 2.11l-.26 4.07c-.05.7.13 1.23.54 1.6.4.35.98.53 1.72.53H9.9v1.67H8.8ZM14.09 23.08v-1.67h1.1c.74 0 1.3-.18 1.72-.54.4-.36.59-.89.56-1.6l-.26-4.06c-.06-.84.1-1.55.5-2.11.4-.57.97-.92 1.69-1.06a2.63 2.63 0 0 1-1.7-1.06 3.19 3.19 0 0 1-.49-2.11l.26-4.06c.03-.7-.16-1.24-.56-1.6a2.5 2.5 0 0 0-1.72-.54h-1.1V1h1.1c1.33 0 2.37.34 3.12 1.01.76.66 1.1 1.59 1.02 2.8l-.26 4.06c-.05.85.07 1.45.37 1.8.32.37.97.55 1.98.55H24v1.67h-2.58c-1 0-1.66.18-1.98.54-.3.36-.42.95-.37 1.78l.25 4.07c.08 1.2-.25 2.13-1 2.8a4.52 4.52 0 0 1-3.13 1h-1.1Z" />
  </Icon>
);

export const Eye = (props) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.14 12a18.68 18.68 0 0 0 2.46 3.32C6.4 17.22 8.9 19 12 19c3.1 0 5.6-1.77 7.4-3.68A18.68 18.68 0 0 0 21.86 12a18.68 18.68 0 0 0-2.46-3.32C17.6 6.78 15.1 5 12 5 8.9 5 6.4 6.77 4.6 8.68A18.68 18.68 0 0 0 2.14 12ZM23 12l.9-.45h-.01l-.01-.03a10.61 10.61 0 0 0-.2-.35 20.67 20.67 0 0 0-2.83-3.85C18.9 5.22 15.9 3 12 3 8.1 3 5.1 5.23 3.15 7.32a20.67 20.67 0 0 0-3.03 4.2v.02H.1v.01L1 12l-.9-.45a1 1 0 0 0 0 .9L1 12l-.9.45h.01l.01.03a8.27 8.27 0 0 0 .2.35 20.67 20.67 0 0 0 2.83 3.85C5.1 18.78 8.1 21 12 21c3.9 0 6.9-2.23 8.85-4.32a20.67 20.67 0 0 0 3.03-4.2v-.02h.01v-.01L23 12Zm0 0 .9.45a1 1 0 0 0 0-.9L23 12Z"
    />
    <path d="M12 10a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-4 2a4 4 0 1 1 8 0 4 4 0 0 1-8 0Z" />
  </Icon>
);

export const EyeOff = (props) => (
  <Icon {...props}>
    <path d="M12 5c-.63 0-1.26.07-1.87.21a1 1 0 1 1-.46-1.94C10.44 3.09 11.22 3 12 3v1-1c3.9 0 6.9 2.23 8.85 4.32a20.68 20.68 0 0 1 3.03 4.2v.02h.01v.01L23 12l-.88-.47-.26.47c-.11-.2-.26-.43-.43-.7a18.68 18.68 0 0 0-2.03-2.62C17.6 6.78 15.1 5 12 5Zm9.86 7a17.5 17.5 0 0 1-1.79 2.55 1 1 0 0 0 1.54 1.28 19.5 19.5 0 0 0 2.27-3.36 1 1 0 0 0 .01-.92L23 12l-.9.45v-.02l-.03-.05a10.32 10.32 0 0 0-.21-.38ZM5.46 5.27a1 1 0 0 1 1.3.08l3.82 3.82h.01l4.23 4.24h.01l3.82 3.82a1 1 0 0 1-.1 1.5A11.07 11.07 0 0 1 12.02 21H12c-3.9 0-6.9-2.23-8.85-4.32a20.68 20.68 0 0 1-3.03-4.2v-.02H.1v-.01L1 12l-.9.45a1 1 0 0 1 .02-.92 19.45 19.45 0 0 1 5.33-6.26ZM2.13 12c.11.2.26.43.43.7.47.72 1.15 1.67 2.03 2.62C6.4 17.22 8.9 19 12 19a9.07 9.07 0 0 0 4.37-1.21l-2.32-2.33a4 4 0 0 1-5.51-5.51L5.99 7.4A17.45 17.45 0 0 0 2.14 12Zm7.89-.56a2 2 0 0 0 2.53 2.53l-2.53-2.53Z" />
    <path d="M.3.3a1 1 0 0 1 1.4 0l22 22a1 1 0 0 1-1.4 1.4l-22-22A1 1 0 0 1 .3.3Z" />
  </Icon>
);

export const Save = (props) => (
  <Icon {...props}>
    <path d="M5 4a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V8.41L15.59 4H5ZM2.88 2.88A3 3 0 0 1 5 2h11a1 1 0 0 1 .7.3l5 5a1 1 0 0 1 .3.7v11a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 .88-2.12Z" />
    <path d="M6 13a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v8a1 1 0 1 1-2 0v-7H8v7a1 1 0 1 1-2 0v-8ZM7 2a1 1 0 0 1 1 1v4h7a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1Z" />
  </Icon>
);

export const Import = (props) => (
  <Icon {...props}>
    <path d="M11.3 11.3a1 1 0 0 1 1.4 0l4 4a1 1 0 0 1-1.4 1.4L12 13.42l-3.3 3.3a1 1 0 0 1-1.4-1.42l4-4Z" />
    <path d="M12 11a1 1 0 0 1 1 1v9a1 1 0 1 1-2 0v-9a1 1 0 0 1 1-1Z" />
    <path d="M8.66 2a9 9 0 0 1 8.82 6H18a6 6 0 0 1 2.87 11.27 1 1 0 0 1-.96-1.76A4 4 0 0 0 18 10h-1.26a1 1 0 0 1-.97-.75 7 7 0 1 0-12.02 6.39 1 1 0 1 1-1.5 1.32A9 9 0 0 1 8.66 2.01Z" />
    <path d="M11.3 11.3a1 1 0 0 1 1.4 0l4 4a1 1 0 0 1-1.4 1.4L12 13.42l-3.3 3.3a1 1 0 0 1-1.4-1.42l4-4Z" />
  </Icon>
);

export const DragHandle = (props) => (
  <Icon {...props}>
    <path d="M10 8a2 2 0 1 1 4 0 2 2 0 0 1-4 0ZM17 8a2 2 0 1 1 4 0 2 2 0 0 1-4 0ZM3 8a2 2 0 1 1 4 0 2 2 0 0 1-4 0ZM10 16a2 2 0 1 1 4 0 2 2 0 0 1-4 0ZM17 16a2 2 0 1 1 4 0 2 2 0 0 1-4 0ZM3 16a2 2 0 1 1 4 0 2 2 0 0 1-4 0Z" />
  </Icon>
);

export const Sun = (props) => (
  <Icon {...props}>
    <path d="M12 8a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-6 4a6 6 0 1 1 12 0 6 6 0 0 1-12 0ZM12 0a1 1 0 0 1 1 1v2a1 1 0 1 1-2 0V1a1 1 0 0 1 1-1ZM12 20a1 1 0 0 1 1 1v2a1 1 0 1 1-2 0v-2a1 1 0 0 1 1-1ZM3.51 3.51a1 1 0 0 1 1.42 0l1.42 1.42a1 1 0 0 1-1.42 1.42L3.51 4.93a1 1 0 0 1 0-1.42ZM17.65 17.65a1 1 0 0 1 1.42 0l1.42 1.42a1 1 0 0 1-1.42 1.42l-1.42-1.42a1 1 0 0 1 0-1.42ZM0 12a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1ZM20 12a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2h-2a1 1 0 0 1-1-1ZM6.35 17.65a1 1 0 0 1 0 1.42l-1.42 1.42a1 1 0 0 1-1.42-1.42l1.42-1.42a1 1 0 0 1 1.42 0ZM20.49 3.51a1 1 0 0 1 0 1.42l-1.42 1.42a1 1 0 1 1-1.42-1.42l1.42-1.42a1 1 0 0 1 1.42 0Z" />
  </Icon>
);

export const Moon = (props) => (
  <Icon {...props}>
    <path d="M12.08 2.5a1 1 0 0 1-.07 1.1 6 6 0 0 0 8.4 8.39 1 1 0 0 1 1.59.9A10 10 0 1 1 11.12 2a1 1 0 0 1 .96.5ZM9.32 4.45a8 8 0 1 0 10.24 10.24A8 8 0 0 1 9.32 4.44Z" />
  </Icon>
);

export const Smile = (props) => (
  <Icon {...props}>
    <path d="M12 3a9 9 0 1 0 0 18 9 9 0 0 0 0-18ZM1 12a11 11 0 1 1 22 0 11 11 0 0 1-22 0Z" />
    <path d="M8.8 13.4a1 1 0 0 0-1.6 1.2L8 14l-.8.6.01.02.02.02a3.88 3.88 0 0 0 .32.36c.21.23.52.52.92.8.8.59 2 1.2 3.53 1.2 1.53 0 2.73-.61 3.53-1.2a6.95 6.95 0 0 0 1.24-1.16l.02-.02v-.01h.01L16 14l.8.6a1 1 0 0 0-1.6-1.2 2 2 0 0 1-.2.22c-.14.15-.36.36-.65.57-.58.42-1.38.81-2.35.81-.97 0-1.77-.39-2.35-.8a4.95 4.95 0 0 1-.85-.8ZM8 9a1 1 0 0 1 1-1h.01a1 1 0 0 1 0 2H9a1 1 0 0 1-1-1ZM14 9a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2H15a1 1 0 0 1-1-1Z" />
  </Icon>
);

export const Bin = (props) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 6a1 1 0 011-1h18a1 1 0 110 2H3a1 1 0 01-1-1z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 3a1 1 0 00-1 1v1h6V4a1 1 0 00-1-1h-4zm7 2V4a3 3 0 00-3-3h-4a3 3 0 00-3 3v1H5a1 1 0 00-1 1v14a3 3 0 003 3h10a3 3 0 003-3V6a1 1 0 00-1-1h-2zM6 7v13a1 1 0 001 1h10a1 1 0 001-1V7H6z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 10a1 1 0 011 1v6a1 1 0 11-2 0v-6a1 1 0 011-1zM14 10a1 1 0 011 1v6a1 1 0 11-2 0v-6a1 1 0 011-1z"
    />
  </Icon>
);
