import * as React from "react";
import classnames from "classnames";
import "../styles/button.css";

const Button = ({
  className,
  onClick,
  Icon,
  iconPosition = "start",
  kind = "primary",
  compact,
  disabled,
  children,
}) => {
  const classes = classnames("button", {
    [`button--${kind}`]: kind,
    [className]: className,
    "button--icon-only": !children,
    "button--compact": compact,
  });

  return (
    <button className={classes} onClick={onClick} disabled={disabled}>
      {Icon && iconPosition === "start" && <Icon />}
      {children}
      {Icon && iconPosition === "end" && <Icon />}
    </button>
  );
};

export default Button;
