import * as React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import classnames from "classnames";
import "../styles/input.css";
import "../styles/rich-text-input.css";
import Label from "./label";

const RichTextInput = ({
  label,
  error,
  value,
  onChange,
  inputRef,
  decoration,
  decorationPosition = "left",
  disabled,
}) => {
  const modules = {
    toolbar: ["bold", "italic", "underline", "strike", "clean"],
  };

  const formats = ["bold", "italic", "underline", "strike"];

  const classes = classnames("input input--rich-text", {
    "input--error": error,
    "input--right-decoration": decorationPosition === "right",
  });

  return (
    <div className={classes}>
      {label && <Label>{label}</Label>}
      <div className="input__outer">
        {decoration && <div className="input__decoration">{decoration}</div>}
        <div className="input__inner">
          <ReactQuill
            ref={inputRef}
            className="input__field"
            theme="bubble"
            value={value}
            onChange={onChange}
            modules={modules}
            formats={formats}
            readOnly={disabled}
          />
        </div>
      </div>
      {error && <div className="input__error">{error}</div>}
    </div>
  );
};

export default RichTextInput;
