import * as Icons from "../components/icons";

const BASIC_TAGS = ["div", "span"];
const TEXT_TAGS = ["h1", "h2", "h3", "h4", "h5", "h6", "p", "em", "sub"];
const ADVANCED_TAGS = ["summary", "details", "hr"];
const ALL_TAGS = [...BASIC_TAGS, ...TEXT_TAGS, ...ADVANCED_TAGS];

export const list = {
  __CONTAINER__: {
    Icon: Icons.Brackets,
    title: "Conteneur",
    rules: {
      defaultTag: "div",
      allowedTags: ALL_TAGS,
      defaultMarkup: [],
    },
  },
  TextFormat: {
    Icon: Icons.Text,
    title: "Texte simple",
    rules: {
      defaultTag: "div",
      allowedTags: [...BASIC_TAGS, ...TEXT_TAGS],
      defaultMarkup: "Texte simple",
      childrenNotAllowed: true,
    },
  },
  RichTextFormat: {
    Icon: Icons.Text,
    title: "Texte riche",
    rules: {
      defaultTag: "div",
      allowedTags: [...BASIC_TAGS, ...TEXT_TAGS],
      defaultMarkup: "Texte riche",
      childrenNotAllowed: true,
      advancedOptions: true,
    },
  },
  ImgFormat: {
    Icon: Icons.Image,
    title: "Image",
    rules: {
      defaultTag: "div",
      allowedTags: BASIC_TAGS,
      defaultMarkup:
        '<img alt="" src="https://placeholder.valraiso.net/500x500" width="500" height="500" />',
      childrenNotAllowed: true,
    },
  },
  PictureFormat: {
    Icon: Icons.Image,
    title: "Image responsive",
    rules: {
      defaultTag: "picture",
      allowedTags: ["picture"],
      defaultMarkup:
        '<img alt="" src="https://placeholder.valraiso.net/500x500" width="500" height="500" />',
      childrenNotAllowed: true,
      advancedOptions: true,
    },
  },
  LinkFormat: {
    Icon: Icons.Link,
    title: "Lien",
    rules: {
      defaultTag: "a",
      allowedTags: ["a"],
      defaultMarkup: "Lien",
      advancedOptions: true,
    },
  },
  EsfBookingLinkFormat: {
    Icon: Icons.Cart,
    title: "Panier esf",
    rules: {
      defaultTag: "div",
      allowedTags: BASIC_TAGS,
      defaultMarkup: "Panier esf",
    },
  },
  TableFormat: {
    Icon: Icons.Table,
    title: "Tableau",
    rules: {
      defaultTag: "table",
      allowedTags: ["table"],
      defaultMarkup: [
        { name: "Ligne d'ancrage", markup: "<tr><td></td></tr>" },
      ],
      childrenNotAllowed: true,
    },
  },
  TarifsTableFormat: {
    Icon: Icons.Cart,
    title: "Tableau avec paniers esf",
    rules: {
      defaultTag: "table",
      allowedTags: ["table"],
      defaultMarkup: [
        { name: "Ligne d'ancrage", markup: "<tr><td></td></tr>" },
      ],
      childrenNotAllowed: true,
    },
  },
  IconFormat: {
    Icon: Icons.Smile,
    title: "Icône",
    rules: {
      defaultTag: "div",
      allowedTags: BASIC_TAGS,
      defaultMarkup:
        '<svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/static/images/sprites/icons.svg#icon-XXX"></use></svg>',
      childrenNotAllowed: true,
    },
  },
  VideoFormat: {
    Icon: Icons.Film,
    title: "Vidéo",
    rules: {
      defaultTag: "div",
      allowedTags: BASIC_TAGS,
      defaultMarkup: "Cliquez ici",
      childrenNotAllowed: true,
    },
  },
  SlideshowFormat: {
    Icon: Icons.Image,
    title: "Carousel d'images",
    rules: {
      defaultTag: "div",
      allowedTags: BASIC_TAGS,
      defaultMarkup:
        '<img alt="" src="https://placeholder.valraiso.net/600x350" width="600" height="350" />',
      childrenNotAllowed: true,
    },
  },
  GoogleMapsFormat: {
    Icon: Icons.Map,
    title: "Carte Google Maps",
    rules: {
      defaultTag: "div",
      allowedTags: BASIC_TAGS,
      defaultMarkup:
        '<iframe src="https://maps.google.com/maps?q=8%20Place%20Colbert%20Lyon&t=&z=11&ie=UTF8&iwloc=&output=embed" width="1000" height="450" style="max-width: 100%;" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>',
      childrenNotAllowed: true,
    },
  },
  IframeFormat: {
    Icon: Icons.Globe,
    title: "Iframe",
    rules: {
      defaultTag: "div",
      allowedTags: BASIC_TAGS,
      defaultMarkup:
        '<iframe src="https://www.valraiso.net" width="1000" height="450" style="max-width: 100%;" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>',
      childrenNotAllowed: true,
    },
  },
  YoutubePlaylistFormat: {
    Icon: Icons.Play,
    title: "Playlist YouTube",
    rules: {
      defaultTag: "div",
      allowedTags: BASIC_TAGS,
      defaultMarkup: "Cliquez ici",
      childrenNotAllowed: true,
    },
  },
  SpotifyPlaylistFormat: {
    Icon: Icons.Play,
    title: "Playlist Spotify",
    rules: {
      defaultTag: "div",
      allowedTags: BASIC_TAGS,
      defaultMarkup:
        '<iframe src="https://open.spotify.com/embed/playlist/37i9dQZF1DWXRqgorJj26U" width="800" height="600" style="display:block;" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>',
      childrenNotAllowed: true,
    },
  },
};
