import * as React from "react";
import Label from "./label";
import "../styles/checkbox.css";

const Checkbox = ({ label, value, compact, onChange, disabled }) => {
  const toggleCheckbox = ({ target }) => onChange(target.checked);
  return (
    <label className="checkbox">
      <input
        type="checkbox"
        className="checkbox__input"
        onChange={toggleCheckbox}
        checked={value}
        disabled={disabled}
      />
      <span className="checkbox__checkmark"></span>
      <Label noLabelTag compact={compact}>
        {label}
      </Label>
    </label>
  );
};

export default Checkbox;
