import * as React from "react";
import classnames from "classnames";
import { useDraggable, useDroppable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import * as Icons from "./icons";
import * as MicroFormats from "../data/micro-formats";
import "../styles/format-tree.css";
import AdvancedOptions from "./advanced-options";
import Button from "./button";

const MICRO_FORMATS = MicroFormats.list;

const DropZones = ({ item, childrenNotAllowed }) => {
  const { setNodeRef: setDroppableBeforeRef } = useDroppable({
    id: `${item.id}-before`,
    data: { item, position: "before", accepts: ["mf", "inner"] },
  });
  const { setNodeRef: setDroppableInsideRef } = useDroppable({
    id: `${item.id}-inside`,
    data: { item, position: "inside", accepts: ["mf", "inner"] },
  });
  const { setNodeRef: setDroppableAfterRef } = useDroppable({
    id: `${item.id}-after`,
    data: { item, position: "after", accepts: ["mf", "inner"] },
  });

  return (
    <>
      <div
        ref={setDroppableBeforeRef}
        className="format-tree__item-dropzone-before"
      />
      <div
        ref={setDroppableAfterRef}
        className="format-tree__item-dropzone-after"
      />
      {!childrenNotAllowed && (
        <div
          ref={setDroppableInsideRef}
          className="format-tree__item-dropzone-inside"
        />
      )}
    </>
  );
};

const ItemMarkup = ({ markup, openAdvancedOptions }) => {
  const displayedMarkup = Array.isArray(markup)
    ? `${markup.length} ligne${markup.length > 1 ? "s" : ""}`
    : markup;
  return (
    <div className="format-tree__markup">
      {"</>"}
      <pre onClick={() => openAdvancedOptions({ focusOn: "markup" })}>
        {displayedMarkup}
      </pre>
    </div>
  );
};

const TreeItem = ({ item, removeItem, updateItem, hasDuplicateSelector }) => {
  const { id, format, tag, selector, children, markup } = item;
  const isContainer = format === "__CONTAINER__";
  const microFormat = MICRO_FORMATS[format];
  const { Icon, rules } = microFormat;
  const [advancedOptionsOpened, setAdvancedOptionsOpened] = React.useState();

  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id,
    data: { item, type: "mf" },
  });

  const style = { transform: CSS.Translate.toString(transform) };

  const dragging = !!transform;

  const openAdvancedOptions = (presets) => {
    setAdvancedOptionsOpened(presets);
  };

  const closeAdvancedOptions = () => {
    setAdvancedOptionsOpened(undefined);
  };

  const closeAdvancedOptionsOnOusideClick = React.useCallback((e) => {
    const target = e.target.closest(".advanced-options");
    if (target) return;
    closeAdvancedOptions();
  }, []);

  React.useEffect(() => {
    if (dragging) {
      closeAdvancedOptions();
    }
  }, [dragging]);

  React.useEffect(() => {
    if (advancedOptionsOpened) {
      setTimeout(
        () =>
          document.addEventListener("click", closeAdvancedOptionsOnOusideClick),
        0
      );
      return () =>
        document.removeEventListener(
          "click",
          closeAdvancedOptionsOnOusideClick
        );
    }
  }, [advancedOptionsOpened, closeAdvancedOptionsOnOusideClick]);

  const classes = classnames("format-tree__item", {
    "format-tree__item--duplicate": hasDuplicateSelector,
  });

  return (
    <div className={classes} style={style} {...attributes} tabIndex={-1}>
      <div
        className="format-tree__drag-handle"
        ref={setNodeRef}
        {...listeners}
        {...attributes}
        tabIndex={-1}
      >
        <Icons.DragHandle className="format-tree__drag-handle-icon" />
      </div>
      <div className="format-tree__item-name">
        <button
          className="format-tree__item-icon-button"
          onClick={() => openAdvancedOptions({})}
        >
          <Icon className="format-tree__item-icon" />
        </button>
        <DropZones item={item} childrenNotAllowed={rules.childrenNotAllowed} />
        <div className="format-tree__item-name-right">
          <div className="format-tree__item-key">
            <span
              className="format-tree__item-key-tag"
              onClick={() => openAdvancedOptions({ focusOn: "tag" })}
            >
              {tag}
            </span>
            <span
              className="format-tree__item-key-selector"
              onClick={() => openAdvancedOptions({ focusOn: "selector" })}
            >
              {selector}
            </span>
          </div>
          {!isContainer && (
            <div className="format-tree__item-kind">{format}</div>
          )}
        </div>
        <button
          className="format-tree__item-remove"
          onClick={() => removeItem(id)}
        >
          <Icons.Bin className="format-tree__item-remove-icon" />
        </button>
        {advancedOptionsOpened && (
          <AdvancedOptions
            presets={advancedOptionsOpened}
            item={item}
            microFormat={microFormat}
            updateItem={updateItem}
          />
        )}
      </div>
      {children ? (
        <div className="format-tree__children">
          <TreeItems
            tree={children}
            removeItem={removeItem}
            updateItem={updateItem}
          />
        </div>
      ) : (
        <ItemMarkup markup={markup} openAdvancedOptions={openAdvancedOptions} />
      )}
    </div>
  );
};

const TreeItems = React.memo(({ tree, removeItem, updateItem }) => {
  const selectors = tree.map((item) => `${item.tag}${item.selector}`);
  return tree.map((item, i) => {
    const matchingSelectors = selectors.filter(
      (selector) => selector === `${item.tag}${item.selector}`
    );
    const hasDuplicateSelector = matchingSelectors.length > 1;
    return (
      <TreeItem
        key={i}
        item={item}
        removeItem={removeItem}
        updateItem={updateItem}
        hasDuplicateSelector={hasDuplicateSelector}
      />
    );
  });
});

const FormatTree = ({ tree, removeItem, updateItem, addFormat, empty }) => {
  const { setNodeRef } = useDroppable({
    id: "inner",
    data: { accepts: ["inner"] },
  });
  return (
    <div className="format-tree">
      <div ref={setNodeRef} className="format-tree__inner">
        <div className="format-tree__tree">
          {empty && (
            <Button
              kind="secondary-alt"
              Icon={Icons.Plus}
              iconPosition="end"
              className="format-tree__empty-message"
              onClick={addFormat}
            >
              Démarrez la création d'un nouveau format
            </Button>
          )}
          <TreeItems
            tree={tree}
            removeItem={removeItem}
            updateItem={updateItem}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(FormatTree);
