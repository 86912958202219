import * as React from "react";
import { motion } from "framer-motion";
import Input from "./input";
import RichTextInput from "./rich-text-input";
import Checkbox from "./checkbox";
import Label from "./label";
import Button from "./button";
import * as Icons from "./icons";
import "../styles/details.css";

const Variants = ({ value, addVariant, updateVariants, removeVariant }) => {
  return (
    <div className="details__variants">
      <Label noLabelTag>
        Variantes{" "}
        <Button
          kind="secondary"
          Icon={Icons.Plus}
          compact
          onClick={addVariant}
        ></Button>
      </Label>
      <div className="details__variants-inner">
        {value.map((variant, i) => {
          const { name, cssClass } = variant;
          return (
            <div key={i} className="details__variants-item">
              <Input
                type="text"
                placeholder="Nom"
                value={name}
                onChange={updateVariants("name", i)}
                inline
              />
              <Input
                type="text"
                placeholder="Classe CSS"
                value={cssClass}
                onChange={updateVariants("cssClass", i)}
                inline
              />
              <Button
                kind="danger"
                Icon={Icons.Bin}
                compact
                onClick={removeVariant(i)}
              ></Button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const Details = ({ currentFormat, setCurrentFormat, disabled }) => {
  const updateField = (field) => (value) => {
    setCurrentFormat((current) => {
      if (!!value) {
        return { ...current, [field]: value };
      } else {
        const isCssClass = field === "cssClass";
        if (isCssClass) {
          return { ...current, [field]: "new-format" };
        } else {
          return Object.keys(current)
            .filter((key) => key !== field)
            .reduce((acc, key) => {
              return { ...acc, [key]: current[key] };
            }, {});
        }
      }
    }, {});
  };

  const addVariant = () => {
    setCurrentFormat((current) => {
      const { variants = [] } = current;
      const newIndex = variants.length + 1;
      const name = `Variante n°${newIndex}`;
      const cssClass = `variant-${newIndex}`;
      const newVariant = { name, cssClass };
      return { ...current, variants: [...variants, newVariant] };
    });
  };

  const updateVariants = (field, index) => (value) => {
    setCurrentFormat((current) => {
      const { variants = [] } = current;
      const newVariants = variants.map((variant, i) => {
        if (i === index) {
          return { ...variant, [field]: value };
        } else {
          return variant;
        }
      });
      if (!newVariants.length) {
        return Object.keys(current)
          .filter((key) => key !== "variants")
          .reduce((acc, key) => {
            return { ...acc, [key]: current[key] };
          }, {});
      }
      return { ...current, variants: newVariants };
    });
  };

  const removeVariant = (index) => (e) => {
    setCurrentFormat((current) => {
      const { variants = [] } = current;
      const newVariants = variants.filter((_, i) => i !== index);
      if (!newVariants.length) {
        return Object.keys(current)
          .filter((key) => key !== "variants")
          .reduce((acc, key) => {
            return { ...acc, [key]: current[key] };
          }, {});
      }
      return { ...current, variants: newVariants };
    });
  };

  return (
    <motion.div
      className="details"
      initial={{ height: 0, opacity: 0 }}
      animate={{ height: "auto", opacity: 1 }}
      exit={{ height: 0, opacity: 0, overflow: "hidden" }}
    >
      <Input
        type="text"
        label="Nom du format"
        value={currentFormat?.name || ""}
        disabled={disabled}
        onChange={updateField("name")}
      />
      <Input
        type="text"
        label="Groupe"
        value={currentFormat?.group || ""}
        disabled={disabled}
        onChange={updateField("group")}
      />
      <RichTextInput
        label="Description"
        value={currentFormat?.description || ""}
        disabled={disabled}
        onChange={updateField("description")}
      />
      <Input
        type="text"
        label="Classe CSS"
        value={currentFormat?.cssClass || ""}
        disabled={disabled}
        onChange={updateField("cssClass")}
      />
      <Checkbox
        label="Unique"
        value={currentFormat?.unique ?? false}
        onChange={updateField("unique")}
        disabled={disabled}
      />
      <Checkbox
        label="Générer la prévisualisation automatiquement"
        value={currentFormat?.preview ?? false}
        onChange={updateField("preview")}
        disabled={disabled}
      />
      <Variants
        value={currentFormat?.variants || []}
        addVariant={addVariant}
        updateVariants={updateVariants}
        removeVariant={removeVariant}
        disabled={disabled}
      />
    </motion.div>
  );
};

export default Details;
