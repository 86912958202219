import * as React from "react";
import Input from "./input";
import Checkbox from "./checkbox";
import Select from "./select";
import Label from "./label";
import Button from "./button";
import * as Icons from "./icons";
import "../styles/micro-format-options.css";

const LABELS = {
  bold: "Gras",
  italic: "Italique",
  list: "Liste",
  orderedList: "Liste ordonée",
  left: "Aligner à gauche",
  center: "Centrer",
  right: "Aligner à droite",
  justify: "Justifier",
  indent: "Indenter",
  outdent: "Désindenter",
  link: "Lien",
  unlink: "Supprimer lien",
  cleanup: "Nettoyer le formatage",
  color: "Couleurs",
  background: "Couleur de fond",
  family: "Police",
  size: "Tailles",
};

const richTextOptions = [
  "bold",
  "italic",
  "list",
  "orderedList",
  "left",
  "center",
  "right",
  "justify",
  "indent",
  "outdent",
  "link",
  "unlink",
  "cleanup",
  "color",
  "background",
  "family",
  "size",
];

const RichTextOptions = ({ item, updateItem }) => {
  const { id, options = {} } = item;
  const { commands = [] } = options;

  const updateOptions = (code) => (value) => {
    const newOptions = commands.includes(code)
      ? {
          commands: commands.filter((option) => option !== code),
        }
      : { commands: [...commands, code] };

    if (!newOptions.commands.length) {
      updateItem(id, "options", undefined);
    } else {
      updateItem(id, "options", newOptions);
    }
  };

  return (
    <div className="micro-format-options micro-format-options--rich-text">
      {richTextOptions.map((option) => {
        return (
          <Checkbox
            key={option}
            label={LABELS[option]}
            value={commands.includes(option)}
            onChange={updateOptions(option)}
            compact
          />
        );
      })}
    </div>
  );
};

const LinkFormatOptions = ({ item, updateItem }) => {
  const { id, options = {} } = item;
  const { defaultHref, defaultTarget } = options;

  const updateOptions = (field) => (value) => {
    const newOptions = {
      ...options,
      [field]: value,
    };

    if (!newOptions.defaultHref) {
      delete newOptions.defaultHref;
    }
    if (!newOptions.defaultTarget) {
      delete newOptions.defaultTarget;
    }

    if (!Object.keys(newOptions).length) {
      updateItem(id, "options", undefined);
    } else {
      updateItem(id, "options", newOptions);
    }
  };

  const targetOptions = ["", "_blank", "_self", "_parent", "_top"];

  return (
    <div className="micro-format-options micro-format-options--link">
      <Input
        type="text"
        label="Url par défaut"
        value={defaultHref}
        onChange={updateOptions("defaultHref")}
        compact
      />
      <Select
        label="Cible"
        value={defaultTarget}
        onChange={updateOptions("defaultTarget")}
        options={targetOptions}
        compact
      />
    </div>
  );
};

const buildOptions = (sizes) => {
  const newSizes = Object.keys(sizes).map((key) => {
    const size = sizes[key];
    const [bound, width] = key.replace("px", "").split("-width: ");
    return { bound, width, image: size };
  });
  return newSizes;
};

const PictureFormatOptions = ({ item, updateItem }) => {
  const { id, options = {} } = item;
  const { sizes = {} } = options;

  const pictureSizes = buildOptions(sizes);

  const updateOptions = React.useCallback(
    (items) => {
      if (items.length) {
        const pictures = items.reduce((acc, size) => {
          const { bound, width, image } = size;
          const key = `${bound}-width: ${width}px`;
          return { ...acc, [key]: image };
        }, {});
        const newOptions = { sizes: pictures };
        updateItem(id, "options", newOptions);
      } else {
        updateItem(id, "options", undefined);
      }
    },
    [id, updateItem]
  );

  const addSize = () => {
    const newSize = {
      bound: "min",
      width: 992,
      image: {
        src: "https://placeholder.valraiso.net/800x400",
        width: 800,
        height: 400,
      },
    };
    const newSizes = [...pictureSizes, newSize];
    updateOptions(newSizes);
  };

  const updateSizes = (field, subField, index, isNumber) => (value) => {
    const newSizes = pictureSizes.map((item, i) => {
      if (i === index) {
        const parsedValue = isNumber ? parseInt(value) : value;
        const fixedValue = isNumber && isNaN(parsedValue) ? 0 : parsedValue;
        if (subField) {
          return {
            ...item,
            [field]: { ...item[field], [subField]: fixedValue },
          };
        }
        return { ...item, [field]: fixedValue };
      } else {
        return item;
      }
    });
    updateOptions(newSizes);
  };

  const removeSize = (index) => () => {
    const newSizes = pictureSizes.filter((_, i) => i !== index);
    updateOptions(newSizes);
  };

  const boundsOptions = ["min", "max"];

  return (
    <div className="micro-format-options micro-format-options--picture">
      <Label noLabelTag>
        Media queries{" "}
        <Button
          kind="secondary"
          Icon={Icons.Plus}
          compact
          onClick={addSize}
        ></Button>
      </Label>
      <div className="micro-format-options__inner">
        {pictureSizes?.map((size, i) => {
          const { bound, width, image } = size;
          return (
            <div key={i} className="micro-format-options__item">
              <div className="micro-format-options__item-top">
                <Select
                  label="Borne"
                  options={boundsOptions}
                  value={bound}
                  inline
                  compact
                  onChange={updateSizes("bound", undefined, i)}
                />
                <Input
                  label="Largeur"
                  value={width}
                  onChange={updateSizes("width", undefined, i)}
                  inline
                  compact
                />
              </div>
              <Label important compact>
                Image
              </Label>
              <Input
                type="text"
                label="URL"
                value={image.src}
                onChange={updateSizes("image", "src", i)}
                inline
              />
              <div className="micro-format-options__item-group">
                <Input
                  type="text"
                  label="Largeur"
                  value={image.width}
                  onChange={updateSizes("image", "width", i, true)}
                  inline
                />
                <Input
                  type="text"
                  label="Hauteur"
                  value={image.height}
                  onChange={updateSizes("image", "height", i, true)}
                  inline
                />
              </div>
              <Button
                className="micro-format-options__item-remove"
                kind="danger"
                Icon={Icons.Bin}
                compact
                onClick={removeSize(i)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const MicroFormatOptions = ({ format, ...props }) => {
  if (format === "RichTextFormat") {
    return <RichTextOptions {...props} />;
  }

  if (format === "LinkFormat") {
    return <LinkFormatOptions {...props} />;
  }

  if (format === "PictureFormat") {
    return <PictureFormatOptions {...props} />;
  }

  return null;
};

export default MicroFormatOptions;
