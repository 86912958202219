import * as React from "react";
import classnames from "classnames";
import Button from "./button";
import * as Icons from "./icons";
import "../styles/popup.css";

const Popup = ({ title, className, close, buttons = [], children }) => {
  const classes = classnames("popup", {
    [className]: className,
  });

  return (
    <div className={classes}>
      <div className="popup__backdrop" onClick={close} />
      <div className="popup__inner">
        {title && <div className="popup__title">{title}</div>}
        <button className="popup__close" onClick={close}>
          <Icons.Cross className="popup__close-icon" />
        </button>
        <div className="popup__scroller">{children}</div>
        {buttons.length > 0 && (
          <div className="popup__buttons">
            {buttons.map((button, i) => {
              const { label, onClick, kind, Icon, iconPosition, compact } =
                button;
              const onButtonClick = onClick === "close" ? close : onClick;
              return (
                <Button
                  key={i}
                  kind={kind}
                  className="popup__button"
                  Icon={Icon}
                  iconPosition={iconPosition}
                  onClick={onButtonClick}
                  compact={compact}
                >
                  {label}
                </Button>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Popup;
